<template>
  <v-app>
    <clientOnly>
      <SiteNavigationLeftDrawer />
    </clientOnly>
    <v-navigation-drawer v-model="drawer" :permanent="!smAndDown" :width="300">
      <template #prepend>
        <v-list-item
          lines="two"
          :title="eventDataStore.eventData.title"
          :subtitle="
            formatDateTime(new Date(eventDataStore.eventData.date_started))
          "
        ></v-list-item>
      </template>

      <v-list class="mt-0 pt-0">
        <UDivider />
        <v-list-subheader>通用</v-list-subheader>
        <UDivider />

        <v-list-item
          title="概览"
          :to="`/events/manage/${route.params.id}`"
        ></v-list-item>

        <v-list-item
          title="设置"
          :to="`/events/manage/${route.params.id}/basic`"
        >
        </v-list-item>

        <v-list-item
          title="详细内容"
          :to="`/events/manage/${route.params.id}/content`"
        >
        </v-list-item>

        <v-list-item
          title="参加者"
          :to="`/events/manage/${route.params.id}/participants`"
        >
        </v-list-item>

        <div v-if="eventDataStore.eventData.is_contest === true">
          <UDivider />
          <v-list-subheader>赛事</v-list-subheader>
          <UDivider />

          <v-list-item
            title="所有记录"
            :to="`/events/manage/${route.params.id}/records`"
          >
          </v-list-item>
        </div>
      </v-list>
      <v-spacer />

      <template #append>
        <v-btn
          block
          rounded="0"
          elevation="0"
          prepend-icon="mdi-calendar"
          color="surface"
          @click="callListPopup()"
        >
          切换活动
        </v-btn>
      </template>
    </v-navigation-drawer>
    <v-main class="color-background">
      <slot></slot>
    </v-main>
    <clientOnly>
      <SiteEventBottomNavigation v-if="smAndDown" />
    </clientOnly>
    <!-- 活动列表 -->
    <EventsManageListPopup ref="listPopupRef" />
  </v-app>
</template>
<script setup lang="ts">
// import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useEventObjectStore } from "@/store/EventObject";
const eventStore = useEventObjectStore();
const eventDataStore = ref(eventStore);
const { smAndDown } = useDisplay();
const route = useRoute();
// Drawer状态
const store = useEventObjectStore();
const drawerState = storeToRefs(store);
const drawer = drawerState.drawer;

function formatDateTime(date: Date) {
  if (new Date(date).toLocaleDateString("zh-CN") !== "Invalid Date") {
    return new Date(date).toLocaleDateString("zh-CN", {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    });
  } else {
    return "";
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const listPopupRef = ref(null) as any;
function callListPopup() {
  if (!listPopupRef.value) {
    return;
  }
  listPopupRef.value.openEventListPopup();
}
</script>
<style scoped>
.color-background {
  background-color: rgb(var(--v-theme-background));
}
.event-list-card {
  border: 1px solid #e0e0e0;
}

.event-list-card--image {
  background-color: #e0e0e0;
}

.v-theme--dark {
  .event-list-card {
    border-color: #333;
  }
}
</style>
