<template>
  <v-bottom-navigation
    grow
    class="corner-cut-1-tr d-md-none"
    style="width: 100%"
  >
    <v-container class="d-flex pa-0 align-center" fluid>
      <v-avatar size="32" rounded="0" class="mx-3" to="/">
        <v-img src="/favicon.ico" alt="Vuetify" />
      </v-avatar>
      <ClientOnly fallback-tag="span">
        <div class="text-subtitle-1 mx-2 font-weight-bold">
          {{ titleStore.title }}
        </div>
      </ClientOnly>
      <v-spacer />
      <site-navigation-left-drawer-user v-if="smAndDown" />
      <v-icon size="24" class="ml-4" @click="switchDrawer"> mdi-menu </v-icon>
      <v-icon size="24" class="mx-4" @click="switchEventDrawer">
        mdi-calendar-edit
      </v-icon>
    </v-container>
  </v-bottom-navigation>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { SiteNavigationDrawerStore } from "@/store/SiteNavigationDrawer";
import { useSiteBottonNavigationTitleStore } from "@/store/SiteBottonNavigationTitle";

import { useEventObjectStore } from "@/store/EventObject";
// 侧边栏状态
const drawerStore = SiteNavigationDrawerStore();
const drawerState = ref(drawerStore.drawer);
// Drawer状态
const evnetStore = useEventObjectStore();
const eventDrawerState = storeToRefs(evnetStore.drawer);

// 响应式断点
const { smAndDown } = useDisplay();

const switchDrawer = () => {
  drawerStore.drawer = !drawerStore.drawer;
  drawerState.value = drawerStore.drawer;
};

const switchEventDrawer = () => {
  evnetStore.drawer = !evnetStore.drawer;
  eventDrawerState.value = evnetStore.drawer;
};
// 当前页面名称
const titleStore = useSiteBottonNavigationTitleStore();
</script>
